import React from 'react';
import { graphql } from 'gatsby';
import { motion, Variants } from 'framer-motion';

import Container from 'components/organisms/Container';
import Banner from 'components/molecules/Banner';
import InfoSection from 'components/molecules/InfoSection';
import { InfoSectionProps } from 'components/molecules/InfoSection/models.d';
import IntroSection from 'components/atoms/IntroSection';
import Seo from 'components/atoms/Seo';

const AboutUs = ({
  data: {
    contentfulAboutUs: {
      banner: { bannerSlide },
      subheading,
      aboutUsDescription: { aboutUsDescription },
      infoSections,
    },
  },
}) => {
  const introVariants: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.4,
        duration: 0.6,
      },
    },
  };

  return (
    <div className="aboutus-page">
      <Seo title="O nas" />
      <Banner slides={bannerSlide} />
      <Container>
        {/* <IntroSection text={aboutUsDescription} /> */}
        <motion.h2
          className="aboutus-page__subheading"
          variants={introVariants}
          initial="hidden"
          animate="visible"
        >
          {subheading}
        </motion.h2>
        <div className="aboutus-page__info-sections">
          {infoSections.map((section: InfoSectionProps) => (
            <InfoSection key={section.heading} {...section} side="right" />
          ))}
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  {
    contentfulAboutUs {
      banner {
        bannerSlide {
          title
          image {
            image {
              gatsbyImageData(formats: WEBP)
            }
            altText
          }
        }
      }
      subheading
      slug
      pageName
      aboutUsDescription {
        aboutUsDescription
      }
      infoSections {
        heading
        text {
          text
        }
        image {
          image {
            gatsbyImageData(formats: WEBP)
          }
          altText
        }
      }
    }
  }
`;

export default AboutUs;
